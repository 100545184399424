<template>
  <div class="mt-1 mb-1">
    <ins
      class="adsbygoogle"
      style="display: block"
      data-ad-client="ca-pub-4701106266680256"
      data-ad-slot="4412680271"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
</template>
<script>
import GlobalUtils from "./../../scripts/GlobalUtils";
export default {
  // (adsbygoogle = window.adsbygoogle || []).push({});
  mounted() {
    if (GlobalUtils.DEBUG) {
      console.log("debug mode");
      return;
    }

    if (GlobalUtils.BROWSER_TYPE != GlobalUtils.BROWSER_TYPE_NORMAL) {
      return;
    }
    this.adsenseCommonScript();
    this.adsenseAddLoad();
  },
  methods: {
    adsenseCommonScript() {
      let el = document.getElementById("commonadsense");
      if (el != null) {
        console.log("adsenseCommonScript - el exist");
      } else {
        var element = document.createElement("script");
        element.src =
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4701106266680256";
        element.async = true;
        element.id = "commonadsense";
        element.setAttribute("crossorigin", "anonymous");
        document.body.appendChild(element);
      }
    },
    adsenseAddLoad() {
      let el = document.getElementById("myadsense");
      if (el != null) {
        console.log("el exist");
      } else {
        let inlineScript = document.createElement("script");
        inlineScript.id = "myadsense";
        inlineScript.type = "text/javascript";
        inlineScript.text =
          "(adsbygoogle = window.adsbygoogle || []).push({});";
        document.getElementsByTagName("body")[0].appendChild(inlineScript);
      }
    },
  },
};
</script>
