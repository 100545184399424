

class Entertainer {
    constructor(key) {
        this.key = key;
        this.property = {};

        this.name = "";
        this.rank = 0;
        this.cute = 0;
        this.sexy = 0;
        this.charisma = 0;
        this.beauty = 0;
        this.pretty = 0;
        this.attractive = 0;//개성
        this.gorgeous = 0;
        this.handsome = 0;
        this.hunhun = 0;
        this.pure = 0;
        this.warm = 0;
        this.calm = 0;//차분
        this.cool = 0;
        this.bright = 0;//명랑한, 밝은
        this.babyface = 0;

        this.firstPropertyKey = "cute";
        this.secondPropertyKey = "hunhun";

        this.setEntertainer();


        this.property["gorgeous"] = this.gorgeous;
        this.property["babyface"] = this.babyface;
        this.property["beauty"] = this.beauty;
        this.property["bright"] = this.bright;
        this.property["sexy"] = this.sexy;
        this.property["charisma"] = this.charisma;

        this.property["attractive"] = this.attractive;

        this.property["hunhun"] = this.hunhun;
        this.property["cool"] = this.cool;
        this.property["pretty"] = this.pretty;
        this.property["warm"] = this.warm;
        this.property["pure"] = this.pure;
        this.property["cute"] = this.cute;
        this.property["calm"] = this.calm;
        this.property["handsome"] = this.handsome;
    }
    sumProperty(entertainer) {
        const keys = Object.keys(this.property);
        keys.forEach((key, index) => {
            this.property[key] += entertainer.property[key];
        });
    }

    sortProperty() {
        const keys = Object.keys(this.property);

        var count = 0;
        var self = this;

        keys.sort(function (a, b) {
            return self.property[b] - self.property[a];
        })
        keys.forEach((key, index) => {
            if (count == 0) {
                self.firstPropertyKey = key;
            } else if (count == 1) {
                self.secondPropertyKey = key;
            }
            count++;
            console.log(key + ": " + this.property[key] + ", count : " + count + ", index : " + index);
        });
    }

    getFirstProperty() {

        let str = "";
        switch (this.firstPropertyKey) {
            case "cute":
                str = "[귀엽고]";
                break;
            case "sexy":
                str = "[섹시하고]";
                break;
            case "charisma":
                str = "[카리스마 있고]";
                break;
            case "beauty":
                str = "[아름답고]";
                break;
            case "pretty":
                str = "[예쁘고]";
                break;
            case "attractive":
                str = "[매력적이고]";
                break;
            case "gorgeous":
                str = "[멋지고]";
                break;
            case "handsome":
                str = "[잘생기고]";
                break;
            case "hunhun":
                str = "[훈훈하고]";
                break;
            case "pure":
                str = "[순수하고]";
                break;
            case "warm":
                str = "[따뜻하고]";
                break;
            case "calm":
                str = "[차분하고]";
                break;
            case "cool":
                str = "[시크하고]";
                break;
            case "bright":
                str = "[밝고]";
                break;
            case "babyface":
                str = "[동안이고]";
                break;
        }
        return str;
    }

    getSecondProperty() {
        let str = "";
        switch (this.secondPropertyKey) {
            case "cute":
                str = "[귀여운]";
                break;
            case "sexy":
                str = "[섹시한]";
                break;
            case "charisma":
                str = "[카리스마 있는]";
                break;
            case "beauty":
                str = "[아름다운]";
                break;
            case "pretty":
                str = "[예쁜]";
                break;
            case "attractive":
                str = "[매력적인]";
                break;
            case "gorgeous":
                str = "[멋진]";
                break;
            case "handsome":
                str = "[잘생긴]";
                break;
            case "hunhun":
                str = "[훈훈한]";
                break;
            case "pure":
                str = "[순수한]";
                break;
            case "warm":
                str = "[따뜻한]";
                break;
            case "calm":
                str = "[차분한]";
                break;
            case "cool":
                str = "[시크한]";
                break;
            case "bright":
                str = "[밝은]";
                break;
            case "babyface":
                str = "[동안의]";
                break;
        }
        return str;
    }

    setEntertainer() {
        switch (this.key) {
            case "none":
                break;
            case "winter":
                this.name = "에스파 윈터";
                this.rank = 1;
                this.cute = 1;
                this.pretty = 1;
                this.pure = 1;
                this.bright = 1;
                this.babyface = 1;
                this.type = 3;
                break;

            case "karina":
                this.name = "에스파 카리나";
                this.rank = 2;
                this.sexy = 1;
                this.charisma = 1;
                this.beauty = 1;
                this.cool = 1;
                this.type = 1;
                break;

            case "iu":
                this.name = "아이유";
                this.rank = 3;
                this.cute = 1;
                this.pretty = 1;
                this.warm = 1;
                this.bright = 1;
                this.babyface = 1;
                this.type = 3;
                break;

            case "jisu":
                this.name = "블랙핑크 지수";
                this.rank = 4;
                this.cute = 1;
                this.pretty = 1;
                this.babyface = 1;
                this.type = 1;
                break;

            case "suzi":
                this.name = "수지";
                this.rank = 5;
                this.sexy = 1;
                this.beauty = 1;
                this.calm = 1;
                this.type = 1;
                break;

            case "jeni":
                this.name = "블랙핑크 제니";
                this.rank = 6;
                this.cute = 1;
                this.sexy = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.type = 3;
                break;

            case "yeji":
                this.name = "ITZY 예지";
                this.rank = 7;
                this.sexy = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.cool = 1;
                this.type = 1;
                break;

            case "minju":
                this.name = "김민주";
                this.rank = 8;
                this.beauty = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 4;
                break;

            case "arin":
                this.name = "오마이걸 아린";
                this.rank = 9;
                this.cute = 1;
                this.pretty = 1;
                this.bright = 1;
                this.babyface = 1;
                this.type = 4;
                break;

            case "wonyoung":
                this.name = "아이브 장원영";
                this.rank = 10;
                this.beauty = 1;
                this.attractive = 1;
                this.pure = 1;
                this.cool = 1;
                this.type = 3;
                break;

            case "chaewon":
                this.name = "김채원";
                this.rank = 11;
                this.cute = 1;
                this.attractive = 1;
                this.pure = 1;
                this.warm = 1;
                this.bright = 1;
                this.babyface = 1;
                this.type = 1;
                break;

            case "roje":
                this.name = "블랙핑크 로제";
                this.rank = 12;
                this.beauty = 1;
                this.pretty = 1;
                this.type = 4;
                break;

            case "yuna":
                this.name = "소녀시대 윤아";
                this.rank = 13;
                this.beauty = 1;
                this.pretty = 1;
                this.cool = 1;
                this.bright = 1;
                this.type = 1;
                break;

            case "teayeon":
                this.name = "소녀시대 태연";
                this.rank = 14;
                this.cute = 1;
                this.charisma = 1;
                this.type = 2;
                break;

            case "chu":
                this.name = "이달의 소녀 츄";
                this.rank = 15;
                this.cute = 1;
                this.pretty = 1;
                this.pure = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 4;
                break;

            case "sana":
                this.name = "트와이스 사나";
                this.rank = 16;
                this.cute = 1;
                this.sexy = 1;
                this.pretty = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 3;
                break;

            case "irin":
                this.name = "레드벨벳 아이린";
                this.rank = 17;
                this.pretty = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "itzy_yuna":
                this.name = "ITZY 유나";
                this.rank = 18;
                this.sexy = 1;
                this.beauty = 1;
                this.pure = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 3;
                break;

            case "joheon":
                this.name = "베리굿 조현";
                this.rank = 19;
                this.pretty = 1;
                this.bright = 1;
                this.type = 5;
                break;

            case "hewon":
                this.name = "강혜원";
                this.rank = 20;
                this.beauty = 1;
                this.pretty = 1;
                this.pure = 1;
                this.type = 5;
                break;

            case "jiho":
                this.name = "오마이걸 지호";
                this.rank = 21;
                this.attractive = 1;
                this.calm = 1;
                this.type = 5;
                break;

            case "hayoung":
                this.name = "프로미스나인 송하영";
                this.rank = 22;
                this.cute = 1;
                this.pretty = 1;
                this.pure = 1;
                this.type = 4;
                break;

            case "sejung":
                this.name = "김세정";
                this.rank = 23;
                this.cute = 1;
                this.attractive = 1;
                this.warm = 1;
                this.type = 1;
                break;

            case "slgi":
                this.name = "레드벨벳 슬기";
                this.rank = 24;
                this.attractive = 1;
                this.calm = 1;
                this.type = 5;
                break;

            case "ryujin":
                this.name = "ITZY 류진";
                this.rank = 25;
                this.charisma = 1;
                this.pretty = 1;
                this.attractive = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "jizel":
                this.name = "에스파 지젤";
                this.rank = 26;
                this.charisma = 1;
                this.attractive = 1;
                this.pure = 1;
                this.type = 2;
                break;

            case "yuzin":
                this.name = "아이브 안유진";
                this.rank = 27;
                this.sexy = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.pure = 1;
                this.bright = 1;
                this.type = 1;
                break;

            case "isu":
                this.name = "아이브 이서";
                this.rank = 28;
                this.cute = 1;
                this.pretty = 1;
                this.pure = 1;
                this.warm = 1;
                this.babyface = 1;
                this.type = 1;
                break;

            case "ningning":
                this.name = "에스파 닝닝";
                this.rank = 29;
                this.attractive = 1;
                this.warm = 1;
                this.type = 5;
                break;

            case "miju":
                this.name = "이미주";
                this.rank = 30;
                this.pretty = 1;
                this.attractive = 1;
                this.pure = 1;
                this.cool = 1;
                this.bright = 1;
                this.type = 3;
                break;

            case "yeeun":
                this.name = "CLC 장예은";
                this.rank = 31;
                this.cute = 1;
                this.charisma = 1;
                this.pretty = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "dahyun":
                this.name = "트와이스 다현";
                this.rank = 32;
                this.cute = 1;
                this.attractive = 1;
                this.pure = 1;
                this.babyface = 1;
                this.type = 1;
                break;

            case "luda":
                this.name = "우주소녀 루다";
                this.rank = 33;
                this.cute = 1;
                this.attractive = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "yua":
                this.name = "오마이걸 유아";
                this.rank = 34;
                this.cute = 1;
                this.charisma = 1;
                this.beauty = 1;
                this.attractive = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "riz":
                this.name = "아이브 리즈";
                this.rank = 35;
                this.cute = 1;
                this.pretty = 1;
                this.attractive = 1;
                this.warm = 1;
                this.type = 2;
                break;

            case "yena":
                this.name = "최예나";
                this.rank = 36;
                this.pretty = 1;
                this.attractive = 1;
                this.pure = 1;
                this.calm = 1;
                this.type = 1;
                break;

            case "zoyuri":
                this.name = "조유리";
                this.rank = 37;
                this.cute = 1;
                this.pretty = 1;
                this.pure = 1;
                this.warm = 1;
                this.bright = 1;
                this.babyface = 1;
                this.type = 1;
                break;

            case "somi":
                this.name = "소미";
                this.rank = 38;
                this.sexy = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.bright = 1;
                this.type = 2;
                break;

            case "backjihun":
                this.name = "프로미스나인 백지헌";
                this.rank = 39;
                this.cute = 1;
                this.pretty = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "bona":
                this.name = "우주소녀 보나";
                this.rank = 40;
                this.beauty = 1;
                this.pretty = 1;
                this.calm = 1;
                this.type = 1;
                break;

            case "inakyung":
                this.name = "프로미스나인 이나경";
                this.rank = 41;
                this.sexy = 1;
                this.charisma = 1;
                this.beauty = 1;
                this.attractive = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "joy":
                this.name = "레드벨벳 조이";
                this.rank = 42;
                this.sexy = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.pure = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 4;
                break;

            case "nayeon":
                this.name = "트와이스 나연";
                this.rank = 43;
                this.cute = 1;
                this.pretty = 1;
                this.pure = 1;
                this.bright = 1;
                this.babyface = 1;
                this.type = 3;
                break;

            case "backjiwon":
                this.name = "프로미스나인 박지원";
                this.rank = 44;
                this.beauty = 1;
                this.pretty = 1;
                this.calm = 1;
                this.type = 1;
                break;

            case "sonaeun":
                this.name = "에이핑크 손나은";
                this.rank = 45;
                this.beauty = 1;
                this.pretty = 1;
                this.attractive = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 4;
                break;

            case "sulhyun":
                this.name = "AOA 설현";
                this.rank = 46;
                this.sexy = 1;
                this.beauty = 1;
                this.attractive = 1;
                this.calm = 1;
                this.type = 4;
                break;

            case "mina":
                this.name = "트와이스 미나";
                this.rank = 47;
                this.beauty = 1;
                this.attractive = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 4;
                break;

            case "jjwi":
                this.name = "트와이스 쯔위";
                this.rank = 48;
                this.beauty = 1;
                this.pure = 1;
                this.warm = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 5;
                break;

            case "lisa":
                this.name = "블랙핑크 리사";
                this.rank = 49;
                this.cute = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.pure = 1;
                this.bright = 1;
                this.type = 2;
                break;

            case "chungha":
                this.name = "청하";
                this.rank = 50;
                this.sexy = 1;
                this.charisma = 1;
                this.attractive = 1;
                this.cool = 1;
                this.type = 4;
                break;

            case "vui":
                this.name = "BTS 뷔";
                this.rank = 1;
                this.sexy = 1;
                this.charisma = 1;
                this.gorgeous = 1;
                this.handsome = 1;
                this.cool = 1;
                this.type = 1;
                break;

            case "junguk":
                this.name = "BTS 정국";
                this.rank = 2;
                this.sexy = 1;
                this.gorgeous = 1;
                this.handsome = 1;
                this.hunhun = 1;
                this.warm = 1;
                this.type = 4;
                break;

            case "jaeheon":
                this.name = "NCT 재현";
                this.rank = 3;
                this.hunhun = 1;
                this.calm = 1;
                this.type = 5;
                break;

            case "jin":
                this.name = "BTS 진";
                this.rank = 4;
                this.handsome = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "btsjimin":
                this.name = "BTS 지민";
                this.rank = 5;
                this.cute = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 1;
                break;

            case "txtsubin":
                this.name = "TXT 수빈";
                this.rank = 6;
                this.sexy = 1;
                this.gorgeous = 1;
                this.handsome = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 1;
                break;

            case "txtyeonjun":
                this.name = "TXT 연준";
                this.rank = 7;
                this.hunhun = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 3;
                break;

            case "chaeunnu":
                this.name = "ASTRO 차은우";
                this.rank = 8;
                this.beauty = 1;
                this.gorgeous = 1;
                this.handsome = 1;
                this.pure = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "btssuga":
                this.name = "BTS 슈가";
                this.rank = 9;
                this.hunhun = 1;
                this.warm = 1;
                this.type = 5;
                break;

            case "nctjungu":
                this.name = "NCT 정우";
                this.rank = 10;
                this.cute = 1;
                this.handsome = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 1;
                break;

            case "backhyen":
                this.name = "EXO 백현";
                this.rank = 11;
                this.handsome = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "boyszuyeon":
                this.name = "THE BOYZ 주연";
                this.rank = 12;
                this.charisma = 1;
                this.handsome = 1;
                this.type = 1;
                break;

            case "junghan":
                this.name = "세븐틴 정한";
                this.rank = 13;
                this.sexy = 1;
                this.hunhun = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "minhyen":
                this.name = "NU'EST 민현";
                this.rank = 14;
                this.gorgeous = 1;
                this.handsome = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 4;
                break;

            case "bumkyu":
                this.name = "TXT 범규";
                this.rank = 15;
                this.cute = 1;
                this.handsome = 1;
                this.pure = 1;
                this.warm = 1;
                this.babyface = 1;
                this.type = 1;
                break;

            case "sungjae":
                this.name = "BTOB 성재";
                this.rank = 16;
                this.cute = 1;
                this.charisma = 1;
                this.hunhun = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 2;
                break;

            case "jhob":
                this.name = "BTS 제이홉";
                this.rank = 17;
                this.hunhun = 1;
                this.pure = 1;
                this.bright = 1;
                this.type = 5;
                break;

            case "wonu":
                this.name = "세븐틴 원우";
                this.rank = 18;
                this.sexy = 1;
                this.handsome = 1;
                this.cool = 1;
                this.type = 1;
                break;

            case "txtteahyen":
                this.name = "TXT 태현";
                this.rank = 19;
                this.gorgeous = 1;
                this.handsome = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "hyunjin":
                this.name = "Stray Kids 현진";
                this.rank = 20;
                this.cute = 1;
                this.pretty = 1;
                this.handsome = 1;
                this.warm = 1;
                this.bright = 1;
                this.type = 3;
                break;

            case "yunghun":
                this.name = "THE BOYZ 영훈";
                this.rank = 21;
                this.handsome = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.type = 2;
                break;

            case "minhyuk":
                this.name = "MONSTA X 민혁";
                this.rank = 22;
                this.handsome = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "nctjaemin":
                this.name = "NCT 재민";
                this.rank = 23;
                this.type = 3;
                break;

            case "hyunjae":
                this.name = "THE BOYZ 현재";
                this.rank = 24;
                this.hunhun = 1;
                this.pure = 1;
                this.cool = 1;
                this.type = 5;
                break;

            case "btobminhyuk":
                this.name = "BTOB 민혁";
                this.rank = 25;
                this.cute = 1;
                this.charisma = 1;
                this.handsome = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "loun":
                this.name = "SF9 로운";
                this.rank = 26;
                this.handsome = 1;
                this.hunhun = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "nctdoyoung":
                this.name = "NCT 도영";
                this.rank = 27;
                this.handsome = 1;
                this.hunhun = 1;
                this.cool = 1;
                this.type = 1;
                break;

            case "jeno":
                this.name = "NCT 제노";
                this.rank = 28;
                this.sexy = 1;
                this.handsome = 1;
                this.type = 2;
                break;

            case "choibomin":
                this.name = "골든차일드 최보민";
                this.rank = 29;
                this.hunhun = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 4;
                break;

            case "yohan":
                this.name = "김요한";
                this.rank = 30;
                this.handsome = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.type = 1;
                break;

            case "gd":
                this.name = "빅뱅 GD";
                this.rank = 31;
                this.cute = 1;
                this.sexy = 1;
                this.charisma = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.type = 3;
                break;

            case "hyungwon":
                this.name = "MONSTA X 형원";
                this.rank = 32;
                this.hunhun = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 5;
                break;

            case "lunjin":
                this.name = "NCT 런쥔";
                this.rank = 33;
                this.pretty = 1;
                this.hunhun = 1;
                this.bright = 1;
                this.type = 3;
                break;

            case "munbin":
                this.name = "ASTRO 문빈";
                this.rank = 34;
                this.hunhun = 1;
                this.pure = 1;
                this.type = 5;
                break;

            case "gangmin":
                this.name = "VERIVERY 강민";
                this.rank = 35;
                this.cute = 1;
                this.gorgeous = 1;
                this.handsome = 1;
                this.pure = 1;
                this.babyface = 1;
                this.type = 4;
                break;

            case "dujun":
                this.name = "하이라이트 윤두준";
                this.rank = 36;
                this.charisma = 1;
                this.hunhun = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 2;
                break;

            case "minkyu":
                this.name = "세븐틴 민규";
                this.rank = 37;
                this.pretty = 1;
                this.handsome = 1;
                this.warm = 1;
                this.calm = 1;
                this.type = 1;
                break;

            case "kai":
                this.name = "EXO 카이";
                this.rank = 38;
                this.sexy = 1;
                this.charisma = 1;
                this.handsome = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 3;
                break;

            case "nctmark":
                this.name = "NCT 마크";
                this.rank = 39;
                this.hunhun = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;

            case "songminho":
                this.name = "WINNER 송민호";
                this.rank = 40;
                this.charisma = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.calm = 1;
                this.bright = 1;
                this.type = 1;
                break;

            case "suho":
                this.name = "EXO 수호";
                this.rank = 41;
                this.gorgeous = 1;
                this.handsome = 1;
                this.warm = 1;
                this.type = 1;
                break;

            case "jiko":
                this.name = "Block B 지코";
                this.rank = 42;
                this.cute = 1;
                this.sexy = 1;
                this.hunhun = 1;
                this.bright = 1;
                this.type = 3;
                break;

            case "dio":
                this.name = "EXO 디오";
                this.rank = 43;
                this.cute = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.warm = 1;
                this.type = 1;
                break;

            case "teamin":
                this.name = "샤이니 태민";
                this.rank = 44;
                this.cute = 1;
                this.sexy = 1;
                this.handsome = 1;
                this.pure = 1;
                this.warm = 1;
                this.type = 3;
                break;

            case "daniel":
                this.name = "강다니엘";
                this.rank = 45;
                this.cute = 1;
                this.charisma = 1;
                this.hunhun = 1;
                this.pure = 1;
                this.warm = 1;
                this.type = 3;
                break;

            case "pio":
                this.name = "Block B 피오";
                this.rank = 47;
                this.hunhun = 1;
                this.pure = 1;
                this.bright = 1;
                this.type = 1;
                break;

            case "EL":
                this.name = "INFINITY 엘";
                this.rank = 48;
                this.sexy = 1;
                this.handsome = 1;
                this.hunhun = 1;
                this.calm = 1;
                this.cool = 1;
                this.type = 2;
                break;


        }
    }

    getTradeUnits() {
        return this.tradeUnits;
    }
}

module.exports = Entertainer;
