<template>
  <div>
    <title-section />

    <div
      class="drop container pt-1"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    >
      <div class="file-upload">
        <div class="image-upload-wrap" v-if="!imageSource">
          <input
            class="file-upload-input"
            type="file"
            @change="onRequestUploadFiles"
            accept="image/*"
          />
          <div class="drag-text" v-if="!imageSource">
            <img
              class="photo-frame"
              src="https://yhmango.com/wp-content/uploads/2022/03/photoframe_50.png"
            />
          </div>
        </div>
      </div>
      <div class="div-img-container">
        <img class="img-circle" :src="this.imageSource" v-if="imageSource" />
      </div>
      <div class="press-frame test mb-2" v-if="!imageSource">
        액자를 눌러 사진을 넣어주세요
      </div>
    </div>
    <div class="result-div ml-3 mr-3" max-width="280">
      <pacman-loader class="my-spinner mb-1" v-if="showSpinner" />
      <div class="result-title mb-2" v-if="resultTitle != null">
        {{ resultTitle }}
      </div>
      <div class="result-content" v-if="resultTitle != null">
        {{ resultContent1 }}
      </div>
      <div class="result-content" v-if="resultTitle != null">
        {{ selectedPrediction }}
      </div>
      <div class="result-content" v-if="resultTitle != null">
        {{ resultContentFront }}
      </div>
      <div class="result-content mb-3" v-if="resultTitle != null">
        <span style="color: #006f84">{{ resultContentCenter }}</span>
        {{ resultContentFar }}
      </div>

      <share-view-2 position="idol" />
      <div>
        <v-btn class="btn_text" dark @click="reTest" v-if="showReTest">
          다시하기
        </v-btn>
      </div>

      <adsense-display />
      <div class="div-caution mt-1 mb-5" v-if="!imageSource">
        *휴대폰 안에서만 동작하므로 어디에도 사진이 저장되지 않습니다.
      </div>
    </div>
  </div>
</template>

<script>
import TitleSection from "./../components/TitleSection.vue";

import "@tensorflow/tfjs";
import * as tmImage from "@teachablemachine/image";
import PacmanLoader from "./../components/PacmanLoader.vue";
import ResultMaker from "./../scripts/ResultMaker";
import AdsenseDisplay from "./../components/adsense/AdsenseDisplay.vue";
import NetworkUtils from "./../scripts/NetworkUtils.js";
import ShareView2 from "./../components/ShareView2.vue";

export default {
  name: "MainPage",
  components: {
    TitleSection,
    PacmanLoader,
    AdsenseDisplay,
    ShareView2,
  },
  props: {},
  data() {
    return {
      maxPredictions: 0,
      model: null,
      webcam: null,
      images: "",
      isDragging: false,
      imageSource: null,
      afterPredict: false,
      myImage: null,
      selectedPrediction: "",
      resultContent1: "",
      resultContentFront: "",
      resultContentCenter: "",
      resultContentFar: "",
      showSpinner: false,
      showReTest: false,
      resultTitle: null,
      urll: "www.google.com",

      showShareView: false,
    };
  },
  computed: {
    getClasses() {
      return { isDragging: this.isDragging };
    },
  },

  mounted() {
    NetworkUtils.countVisitors("idol", "visit");
    this.showShareView = navigator.share;
  },
  methods: {
    async init() {
      //const URL = "https://teachablemachine.withgoogle.com/models/3TZYjjqOc/"; //마블
      //const URL = "https://teachablemachine.withgoogle.com/models/pja1sGSuh/"; //연예인 100명
      const URL = "https://teachablemachine.withgoogle.com/models/8faJhjmjU/"; //연예인 100명 얼굴만 재학습
      const modelURL = URL + "model.json";
      const metadataURL = URL + "metadata.json";

      const flip = true; // whether to flip the webcam
      // load the model and metadata
      // Refer to tmImage.loadFromFiles() in the API to support files from a file picker
      // or files from your local hard drive
      // Note: the pose library adds "tmImage" object to your window (window.tmImage)
      this.model = await tmImage.load(modelURL, metadataURL);
      this.maxPredictions = this.model.getTotalClasses();
    },
    async predict() {
      let predictions = await this.model.predict(this.myImage, false);

      let resultInfo = ResultMaker.make(predictions, this.maxPredictions);
      this.showSpinner = false;
      this.showReTest = true;
      this.selectedPrediction = resultInfo.content;
      this.resultContent1 = resultInfo.content1;
      this.resultContentFront = resultInfo.front;
      this.resultContentCenter = resultInfo.center;
      this.resultContentFar = resultInfo.far;
      this.resultTitle = resultInfo.title;

      let saveData = {};
      saveData.name = resultInfo.name1;
      saveData.id = resultInfo.id1;
      saveData.probability = resultInfo.probability1;

      NetworkUtils.countVisitors("idol", "played");
      sendMessageToApp(MESSAGE_END_GAME);
    },
    dragOver() {
      this.isDragging = true;
    },
    dragLeave() {
      this.isDragging = false;
    },
    drop(e) {
      let files = e.dataTransfer.files;

      this.readFileAndPredict(files);
    },
    onRequestUploadFiles(event) {
      console.log("onRequestUploadFiles : ");

      let files = event.target.files;
      this.readFileAndPredict(files);
    },
    readFileAndPredict(files) {
      // allows only 1 file
      if (files.length === 1) {
        let file = files[0];

        // allows image only
        if (file.type.indexOf("image/") >= 0) {
          var reader = new FileReader();
          this.showSpinner = true;
          reader.onload = (f) => {
            this.imageSource = f.target.result;
            this.isDragging = false;

            //this.$refs.kk.src = f.target.result
            this.myImage = new Image();
            this.myImage.src = f.target.result;
            this.init().then(() => {
              this.predict();
            });
          };
          reader.readAsDataURL(file);
          //this.predict()
        } else {
          this.wrongFileInserted();
          this.isDragging = false;
        }
      }
    },
    readURL() {
      console.log("readURL : ");
    },

    reTest() {
      this.showReTest = false;
      this.imageSource = null;
      this.selectedPrediction = "";
      this.resultTitle = null;
      window.location.reload();
    },

    wrongFileInserted() {
      this.selectedPrediction = "Wrong file inserted";
    },
    share() {
      if (navigator.share) {
        NetworkUtils.countVisitors("idol", "share");
        navigator
          .share({
            title:
              "인공지능이 판단한 나는 " +
              this.resultContentCenter +
              " 얼굴입니다.",
            text: "아이돌 닮은꼴 테스트",
            url: window.location.origin,
          })
          .then(() => console.log("successful share"))
          .catch((error) => console.log(error));
      } else {
        alert("no share function");
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Dongle:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sunflower:wght@300;500&display=swap");

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

body {
  font-family: sans-serif;
  background-color: #eeeeee;
}

.photo-frame {
  object-fit: contain;
  max-width: 180px;
}

.file-upload {
  background-color: #ffffff;
  width: 40%;
  margin: 0 auto;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 10px;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  border: 4px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #15824b;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 150px;
  max-width: 150px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}
.my-spinner {
  text-align: center;
  margin: auto;
}
.img-circle {
  border-radius: 50%;
  width: 170px;
  height: 170px;
  object-fit: cover;
}

.div-img-container {
  text-align: center;
}

.result-div {
  text-align: center;
}
.result-title {
  border-bottom: solid;
  width: fit-content;
  margin: auto;
  color: #1976d2;

  font-size: 1.4rem;
  font-family: "Sunflower", sans-serif;
  font-weight: 500;
}

.result-content {
  width: fit-content;
  margin: auto;
  max-width: 550px;

  font-size: 1.1rem;
  font-family: "Sunflower", sans-serif;
  font-weight: 300;
}

.div-caution {
  width: fit-content;
  margin: auto;
  font-size: 0.9rem;
}

.ad-banner {
  width: 320px;
  margin: auto;
}
.ad-banner2 {
  width: 300px;
  margin: auto;
}
.press-frame {
  font-size: 1.5rem;
  font-family: "Dongle", sans-serif;
  font-weight: 400;
  text-align: center;

  color: #006f84;
}

.btn_text {
  font-size: 1rem;
  font-family: "Sunflower", sans-serif;
  background: linear-gradient(#6ba8f7, #659ce4);
  font-weight: 300;
}

.card {
  width: 40px;
  height: 40px;
  background-color: rgb(197, 63, 63);
  background: linear-gradient(#bdd9e4, #aed0dd);
  box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}

.test {
  animation: fadein 3s;
  -moz-animation: fadein 3s; /* Firefox */
  -webkit-animation: fadein 3s; /* Safari and Chrome */
  -o-animation: fadein 3s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .img-circle {
    width: 140px;
    height: 140px;
  }

  .press-frame {
    font-size: 1.4rem;
  }

  .result-title {
    font-size: 1.2rem;
  }

  .result-content {
    font-size: 0.95rem;
  }
}
</style>
