
import axios from 'axios';
import GlobalUtils from './GlobalUtils';
var uuid = require("uuid");

const axiosConfig = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};

const address = "https://mango-data-station.du.r.appspot.com/commands/";
//const address = "http://172.30.1.36:3000/commands/";
const visitors = address + "visitors";
const balance_url = address + "balance";
const mbti_url = address + "mbti";
const url_inquiry = address + "inquiry";

function countVisitors(pageName, type) {
    if (GlobalUtils.DEBUG) {
        return;
    }
    const params = new URLSearchParams();
    params.append("name", pageName);
    params.append("type", type);

    axios
        .post(
            visitors,
            params,
            axiosConfig
        ).catch((error) => {
            console.log(error);
        })
        .finally(() => {
            console.log("done");
        });
}

function inquiry(name, email, message) {
    // if (GlobalUtils.DEBUG) {
    //     return;
    // }

    const params = new URLSearchParams();
    params.append("name", name);
    params.append("mail", email);
    params.append("message", message);

    axios
        .post(
            url_inquiry,
            params,
            axiosConfig
        ).catch((error) => {
            console.log(error);
            alert("현재 문의하기 기능에 문제가 있습니다. 메일로 의견을 보내주시면 감사하겠습니다.");
        })
        .finally(() => {
            console.log("done");
            alert("문의가 정상적으로 등록되었습니다.");
        });
}

function collectBalance(theme, data) {
    if (GlobalUtils.DEBUG) {
        return;
    }
    const params = new URLSearchParams();
    params.append("theme", theme);
    params.append("data", data);

    axios
        .post(
            balance_url,
            params,
            axiosConfig
        ).catch((error) => {
            console.log(error);
        })
        .finally(() => {
            console.log("done");
        });
}

function reloadPage(response) {
    console.log("reloadPage");
    window.location.href = window.location.origin + "/mbti/" + response.data.roomId;
}

function mbtiUpdate(roomId, name, mbti) {
    let what = "add";
    if (roomId == null || roomId == '') {
        console.log("create roomId : ");
        roomId = makeUuid();
        what = "new";
    }

    const params = new URLSearchParams();
    params.append("what", what);
    params.append("room", roomId);
    params.append("name", name);
    params.append("mbti", mbti);

    postMbti(mbti_url, params, reloadPage);
}

function mbtiRead(roomId, listener) {
    const params = new URLSearchParams();
    params.append("what", "read");
    params.append("room", roomId);
    postMbti(mbti_url, params, listener)
}

function postMbti(url, params, listener) {
    axios
        .post(
            url,
            params,
            axiosConfig
        ).then(response => {
            if (listener != null) {
                listener(response);
            }
        }).catch((error) => {
            console.log(error);
        })
        .finally(() => {
            console.log("done");
        });
}

function makeUuid() {
    if (GlobalUtils.DEBUG) {
        return 1234;
    }
    return uuid.v4();
}

export default { countVisitors, collectBalance, mbtiUpdate, mbtiRead, inquiry }