const Entertainer = require('./Entertainer');
var entertainers = {};

entertainers["winter"] = new Entertainer("winter");
entertainers["karina"] = new Entertainer("karina");
entertainers["iu"] = new Entertainer("iu");
entertainers["jisu"] = new Entertainer("jisu");
entertainers["suzi"] = new Entertainer("suzi");
entertainers["jeni"] = new Entertainer("jeni");
entertainers["yeji"] = new Entertainer("yeji");
entertainers["minju"] = new Entertainer("minju");
entertainers["arin"] = new Entertainer("arin");
entertainers["wonyoung"] = new Entertainer("wonyoung");
entertainers["chaewon"] = new Entertainer("chaewon");
entertainers["roje"] = new Entertainer("roje");
entertainers["yuna"] = new Entertainer("yuna");
entertainers["teayeon"] = new Entertainer("teayeon");
entertainers["chu"] = new Entertainer("chu");
entertainers["sana"] = new Entertainer("sana");
entertainers["irin"] = new Entertainer("irin");
entertainers["itzy_yuna"] = new Entertainer("itzy_yuna");
entertainers["joheon"] = new Entertainer("joheon");
entertainers["hewon"] = new Entertainer("hewon");
entertainers["jiho"] = new Entertainer("jiho");
entertainers["hayoung"] = new Entertainer("hayoung");
entertainers["sejung"] = new Entertainer("sejung");
entertainers["slgi"] = new Entertainer("slgi");
entertainers["ryujin"] = new Entertainer("ryujin");
entertainers["jizel"] = new Entertainer("jizel");
entertainers["yuzin"] = new Entertainer("yuzin");
entertainers["isu"] = new Entertainer("isu");
entertainers["ningning"] = new Entertainer("ningning");
entertainers["miju"] = new Entertainer("miju");
entertainers["yeeun"] = new Entertainer("yeeun");
entertainers["dahyun"] = new Entertainer("dahyun");
entertainers["luda"] = new Entertainer("luda");
entertainers["yua"] = new Entertainer("yua");
entertainers["riz"] = new Entertainer("riz");
entertainers["yena"] = new Entertainer("yena");
entertainers["zoyuri"] = new Entertainer("zoyuri");
entertainers["somi"] = new Entertainer("somi");
entertainers["backjihun"] = new Entertainer("backjihun");
entertainers["bona"] = new Entertainer("bona");
entertainers["inakyung"] = new Entertainer("inakyung");
entertainers["joy"] = new Entertainer("joy");
entertainers["nayeon"] = new Entertainer("nayeon");
entertainers["backjiwon"] = new Entertainer("backjiwon");
entertainers["sonaeun"] = new Entertainer("sonaeun");
entertainers["sulhyun"] = new Entertainer("sulhyun");
entertainers["mina"] = new Entertainer("mina");
entertainers["jjwi"] = new Entertainer("jjwi");
entertainers["lisa"] = new Entertainer("lisa");
entertainers["chungha"] = new Entertainer("chungha");
entertainers["vui"] = new Entertainer("vui");
entertainers["junguk"] = new Entertainer("junguk");
entertainers["jaeheon"] = new Entertainer("jaeheon");
entertainers["jin"] = new Entertainer("jin");
entertainers["btsjimin"] = new Entertainer("btsjimin");
entertainers["txtsubin"] = new Entertainer("txtsubin");
entertainers["txtyeonjun"] = new Entertainer("txtyeonjun");
entertainers["chaeunnu"] = new Entertainer("chaeunnu");
entertainers["btssuga"] = new Entertainer("btssuga");
entertainers["nctjungu"] = new Entertainer("nctjungu");
entertainers["backhyen"] = new Entertainer("backhyen");
entertainers["boyszuyeon"] = new Entertainer("boyszuyeon");
entertainers["junghan"] = new Entertainer("junghan");
entertainers["minhyen"] = new Entertainer("minhyen");
entertainers["bumkyu"] = new Entertainer("bumkyu");
entertainers["sungjae"] = new Entertainer("sungjae");
entertainers["jhob"] = new Entertainer("jhob");
entertainers["wonu"] = new Entertainer("wonu");
entertainers["txtteahyen"] = new Entertainer("txtteahyen");
entertainers["hyunjin"] = new Entertainer("hyunjin");
entertainers["yunghun"] = new Entertainer("yunghun");
entertainers["minhyuk"] = new Entertainer("minhyuk");
entertainers["nctjaemin"] = new Entertainer("nctjaemin");
entertainers["hyunjae"] = new Entertainer("hyunjae");
entertainers["btobminhyuk"] = new Entertainer("btobminhyuk");
entertainers["loun"] = new Entertainer("loun");
entertainers["nctdoyoung"] = new Entertainer("nctdoyoung");
entertainers["jeno"] = new Entertainer("jeno");
entertainers["choibomin"] = new Entertainer("choibomin");
entertainers["yohan"] = new Entertainer("yohan");
entertainers["gd"] = new Entertainer("gd");
entertainers["hyungwon"] = new Entertainer("hyungwon");
entertainers["lunjin"] = new Entertainer("lunjin");
entertainers["munbin"] = new Entertainer("munbin");
entertainers["gangmin"] = new Entertainer("gangmin");
entertainers["dujun"] = new Entertainer("dujun");
entertainers["minkyu"] = new Entertainer("minkyu");
entertainers["kai"] = new Entertainer("kai");
entertainers["nctmark"] = new Entertainer("nctmark");
entertainers["songminho"] = new Entertainer("songminho");
entertainers["suho"] = new Entertainer("suho");
entertainers["jiko"] = new Entertainer("jiko");
entertainers["dio"] = new Entertainer("dio");
entertainers["teamin"] = new Entertainer("teamin");
entertainers["daniel"] = new Entertainer("daniel");
entertainers["pio"] = new Entertainer("pio");
entertainers["EL"] = new Entertainer("EL");

function make(predictions, maxPredictions) {
    /*
    entertainers.forEach(element => {
        console.log(element.name);
    });*/
    /*
        const keys = Object.keys(entertainers);
    
        keys.forEach((key, index) => {
    
            console.log(entertainers[key].name);
        });*/
    let resultInfo = new Object();


    let resultStr = "";
    let entertainerForSum = new Entertainer("none");

    predictions.sort(function (a, b) {
        if (a.probability > b.probability) return -1;
        if (a.probability == b.probability) return 0;
        if (a.probability < b.probability) return 1;
    });

    for (let i = 0; i < maxPredictions; i++) {
        if (predictions[i].probability > 0.02) {
            entertainerForSum.sumProperty(entertainers[predictions[i].className]);
        }

        /*
        let classPrediction =
            predictions[i].className +
            ": " +
            predictions[i].probability.toFixed(2);

        console.log(classPrediction);*/
    }

    let firstEntertainer = entertainers[predictions[0].className];
    let secondEntertainer = entertainers[predictions[1].className];
    resultInfo.name1 = firstEntertainer.name;
    resultInfo.id1 = predictions[0].className;
    resultInfo.probability1 = predictions[0].probability.toFixed(2);

    entertainerForSum.sortProperty();

    let first = entertainerForSum.getFirstProperty();
    let second = entertainerForSum.getSecondProperty();

    //let propertyStr = " 마지막으로 닮은 아이돌들로 분석한 당신의 키워드는" + first + " " + second + " 입니다. ";
    resultInfo.front = " 마지막으로 닮은 아이돌들로 분석한 당신의 키워드는";
    resultInfo.center = first + " " + second;
    resultInfo.far = " 입니다. ";


    //마무리 멘트들 랜덤으로 추가로 넣기
    if (predictions[0].probability > 0.60) {
        resultInfo.title = firstEntertainer.name;
        resultInfo.content1 = " 인공지능이 판단한 닮은 아이돌은 \"" + firstEntertainer.name + "\"입니다. ";

    } else if (predictions[0].probability > 0.50) {
        resultInfo.title = "아이돌 느낌";
        resultInfo.content1 = " 아이돌 느낌이 있으시긴 한데 똑 닮은 사람은 찾질 못했어요.. ";
    } else if (predictions[0].probability > 0.40) {
        resultInfo.title = "배우의 얼굴";
        resultInfo.content1 = " 당신은 아이돌보다 배우의 얼굴에 더 가까운 것 같아요. ";
    } else if (predictions[0].probability > 0.30) {
        resultInfo.title = "미래의 아이돌";
        resultInfo.content1 = " 지금 아이돌 중에 당신에게 견줄만한 사람은 없는 것 같아요. 아이돌에 도전해 보는 건 어떨까요? ";
    } else if (predictions[0].probability > 0.20) {
        resultInfo.title = "아이돌보다 매력적인 일반인";
        resultInfo.content1 = " 아이돌 안 닮으면 어때요. 아이돌 보다 훨씬 매력적인 얼굴 입니다. ";
    }
    else {
        resultInfo.title = "진정한 아이돌";
        resultInfo.content1 = " 당신은 많은 아이돌을 두루두루 닮았습니다. 어쩌면 당신이 진정한 아이돌일지도 모릅니다. ";
    }


    let typeStr = " 성격이 긍정적이고 원만하여 사람들과 잘 어울릴 것으로 보입니다. 대충 사는 걸 좋아해서 가끔은 계획성이 부족하다 느껴질 때도 있지만 좋아하는 일에 대해서는 누구보다 열심인 사람입니다.";
    switch (firstEntertainer.type) {
        case 1:
            typeStr = " 성격이 긍정적이고 원만하여 사람들과 잘 어울릴 것으로 보입니다. 대충 사는 걸 좋아해서 가끔은 계획성이 부족하다 느껴질 때도 있지만 좋아하는 일에 대해서는 누구보다 열심인 사람입니다.";
            break;
        case 2:
            typeStr = " 책임감이 있고 끈기가 있어 맡은 일에 대해서는 확실히 처리하는 사람인 것 같습니다. 가끔 원칙을 중요시 여기는 모습에 융통성이 부족하다는 오해를 받기도 하지만 의외로 꼰대와는 거리가 멀어 보입니다. 어쩌면 완벽주의자가 아닐까요?";
            break;
        case 3:
            typeStr = " 머리가 좋고 재능이 많은 사람으로 톡톡 튀는 매력이 있어 보입니다. 가끔 좋은 머리로 잔재주를 부리기도 하지만 같이 있는 사람을 세상 즐겁게 만들어 주는 것 같습니다.";
            break;
        case 4:
            typeStr = " 배려를 잘하고 따뜻한 마음을 가진 사람으로 보입니다. 가끔은 생각이 많고 안 해도 되는 걱정을 하기도 하지만 모두가 당신과 함께 있고 싶어 할 매력을 가졌습니다.";
            break;
        case 5:
            typeStr = " 적당히 계획적이고 적당히 여유가 있어 보입니다. 어떤 일을 적당히 한다는 뜻이 아니라 특유의 부드러운 느낌으로 어디에도 잘 어울리는 조화의 아이콘입니다.";
            break;
    }
    resultInfo.content = " 사진으로 본 당신의 느낌은";
    resultInfo.content += typeStr;
    //resultInfo.content += propertyStr;

    //return entertainers[predictions[0].className].name + " " + propertyStr;
    return resultInfo;
}

export default { make, }