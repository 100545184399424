<template>
  <v-app>
    <my-appbar />

    <v-content class="pt-3">
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import MyAppbar from "./components/MyAppbar.vue";
export default {
  name: "app",
  components: {
    MyAppbar,
  },
};
</script>

<style scoped></style>
