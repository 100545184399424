const DEBUG = false;
const BROWSER_TYPE_NORMAL = 0;
const BROWSER_TYPE_IOS_APP = 1;
const BROWSER_TYPE_ANDROID_APP = 2;
var BROWSER_TYPE = getBrowserType();

function getBrowserType() {
    let browserType = BROWSER_TYPE_IOS_APP;

    try {
        window.webkit.messageHandlers.iosAppHandler.postMessage("test");
    } catch (error) {
        browserType = BROWSER_TYPE_NORMAL;
        console.log('no iosApp');
    }

    try {
        if (window['androidAppInterface'] == null || window['androidAppInterface'] == undefined) {
            console.log('no android app');
        } else {
            if (window.androidAppInterface.isAndroid()) {
                //console.log('this is android app');
                browserType = BROWSER_TYPE_ANDROID_APP;
            }
        }
    } catch (error) {
        console.log('no android app');
    }
    return browserType;
}

function isMobileApp() {
    if (BROWSER_TYPE == BROWSER_TYPE_IOS_APP || BROWSER_TYPE == BROWSER_TYPE_ANDROID_APP) {
        return true;
    }
    return false;
}

function sendMessageToApp(what) {
    if (BROWSER_TYPE == BROWSER_TYPE_IOS_APP) {
        try {
            window.webkit.messageHandlers.iosAppHandler.postMessage(what);
        } catch (error) {
            console.log('no iosApp');
        }
    } else if (BROWSER_TYPE == BROWSER_TYPE_ANDROID_APP) {
        try {
            if (window.androidAppInterface.sendMessage(what)) {
                console.log('send message complete!');
            }
        } catch (error) {
            console.log('no android interface');
        }
    }
}

global.sendMessageToApp = sendMessageToApp;
global.isMobileApp = isMobileApp;
global.MESSAGE_KAKAO = "kakao";
global.MESSAGE_END_GAME = "end_game";

export default { DEBUG, BROWSER_TYPE, BROWSER_TYPE_ANDROID_APP, BROWSER_TYPE_NORMAL, BROWSER_TYPE_IOS_APP }