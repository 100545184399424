<template>
  <div>
    <idol-page class="pb-9" />
  </div>
</template>

<script>
import IdolPage from "./IdolPage.vue";

export default {
  name: "Home",

  components: {
    IdolPage,
  },
  mounted() {
    var baseUrl = window.location.origin;
  },
};
</script>
